<template>
  <v-card class="elevation-0 ma-8 py-6 px-8">
    <v-row class="ma-0 pb-4">
        <v-card-title>
            Reporte de Costo de Inventario
        </v-card-title>
    </v-row>
    <v-data-table
        :headers="headers" 
        :items="products"
        :loading="loading"
    >
    <template v-slot:[`item.cost`]="{ item }">
        {{((item.last_shopping_price!=null?item.last_shopping_price:item.cost)*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
    </template>
    <template v-slot:[`item.inventory_cost`]="{ item }">
        {{(item.inventory_cost).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
    </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios"
export default {
    data(){
        return{
            loading:true,
            products:[],
            headers:[
                { text: 'Producto', value: 'name' },
                { text: 'Costo', value: 'cost' },
                { text: 'Inventario', value: 'inventory' },
                { text: 'Costo de Inventario', value: 'inventory_cost' },
            ],
            dates:[]
        }
    },
    created(){
        var dates = []
        var current_date = new Date()
        dates[0] = new Date(current_date.getFullYear(), current_date.getMonth(), 1).toLocaleString("sv-SE", {timeZone: "America/Monterrey"}).toString().slice(0, 10)
        dates[1] = new Date(current_date.getFullYear(), current_date.getMonth() + 1, 0).toLocaleString("sv-SE", {timeZone: "America/Monterrey"}).toString().slice(0, 10)
        this.dates = dates
        axios.get(process.env.VUE_APP_BACKEND_ROUTE + "api/v2/inventory_report").then(response=>{//?filter[created_between]=" + this.dates).then(response=>{
            this.products = Object.values(response.data)
            this.loading = false
        })
    },
}
</script>